import React, {useContext, useEffect} from 'react';
import { Helmet } from 'rnd-helmet';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";
import Form from 'form';
import FormContext from "formContext";
import axios from "axios";
import GetRouteByName from 'getRouteByName';
import NoticeInPage from "@Components/NoticeInPage";

function ForgotPassword({intl, staticContext}) {
  const { locale } = intl;
  const {formData, setFormData, currentStep, handleErrors, renderStep, setErrors, setLoading, handleChange} = useContext(FormContext);
  const headerTitleLeftMessage = currentStep === 0 ? 'ResetPassword.Page.Title' : 'ResetPassword.Verification.Page.Title';
  const headerTitleLeft = intl.formatMessage({id: headerTitleLeftMessage})
  const loginPageUrl = GetRouteByName('login').url;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    setFormData([]);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    setErrors();
    setLoading(true);

    if (formData[currentStep] && captcha?.getToken('mrnd_captcha')) {
      formData[currentStep].captchaToken = captcha?.getToken('mrnd_captcha');
    }

    if (currentStep === 0) {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/forgot-password/`, formData[currentStep])
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            renderStep('next');
            setLoading(false);
            captcha?.reset('mrnd_captcha');
          }
        })
        .catch(error => {
          handleErrors(error.response.data);
          setLoading(false);
          captcha?.reset('mrnd_captcha');
        });
    }
    else {
      let formDataFromAllSteps = formData[currentStep];
      if (formData[currentStep] !== undefined) {
        formDataFromAllSteps= Object.assign(formData[currentStep], formData[0]);
      }

      axios.post(`${process.env.REACT_APP_API_PREFIX}/confirm-forgot-password/`, formDataFromAllSteps)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            window.location.href = `${loginPageUrl}?message=reset`;
            setLoading(false);
          }
        })
        .catch(error => {
          handleErrors(error.response.data);
          setLoading(false);
        });
    }
  };

  const noticeSettings = {
    type: 'warning',
    message: intl.formatMessage({id: "Cutover.Login"})
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.ForgotPassword'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary" />
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{headerTitleLeft}</span>
            </h1>
          </div>
        </div>
      </div>
      { process.env.REACT_APP_COUNTRY === "it" &&
        <div className="block block--xs">
          <div className="wrapper block__wrapper">
            <NoticeInPage settings={noticeSettings} style={{maxWidth: '100%'}} />
          </div>
        </div>
      }
      <Form name='reset-password-2-step' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} />
    </>
  )
}

export default injectIntl(ForgotPassword);
